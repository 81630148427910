import React, { useEffect } from 'react';

function DiscordRedirect() {
    useEffect(() => {
        window.location.href = 'https://discord.gg/lennyie';
    }, []);

    return <div>Redirecting to Discord...</div>;
}

export default DiscordRedirect;