import React, { useState, useEffect } from 'react';
import profileImage1 from '../image.png'; // Adjust the path as necessary
import profileImage2 from '../image.png'; // Adjust if you have a different image for the second account

const Home = () => {
  const [inputId, setInputId] = useState('');
  const [officialAccounts, setOfficialAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('https://util.lenny.ie/info')
      .then(response => response.json())
      .then(data => {
        setOfficialAccounts(data.accounts);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching official accounts:', error);
        setIsLoading(false);
      });
  }, []);

  const isIdValid = officialAccounts.some(account => account.id === inputId);

  const images = [profileImage1, profileImage2]; // Array to map images with accounts

  return (
    <div className="min-h-screen bg-gray-900 text-blue-400 flex flex-col justify-between">

      <header className="text-center py-20">
        <h1 className="text-5xl md:text-6xl font-bold mb-6">Welcome to lenny.ie</h1>
        <p className="px-4 text-xl md:text-2xl" style={{ maxWidth: '800px', margin: '0 auto' }}>
          Feel free to contact me to middleman a deal for you or coding projects.
        </p>
      </header>

      {/* Main content */}
      <main className="text-center mb-20">
        <h2 className="text-4xl font-bold text-white mb-8">Official Discord Accounts</h2>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          officialAccounts.map((account, index) => (
            <div key={account.id} className="mb-8 flex justify-center items-center">
              {/* Use the index to match account with its corresponding image */}
              <img src={images[index]} alt={`Discord Profile ${index + 1}`} className="rounded-full w-24 h-24 mr-4" />
              <div>
                <p className="text-lg font-bold">{account.name}</p>
                <p>ID: {account.id}</p>
              </div>
            </div>
          ))
        )}
      </main>

      {/* ID Checker Section */}
      <section className="text-center py-20">
        <h2 className="text-4xl font-bold text-white mb-8">ID Checker</h2>
        <p className="text-xl mb-10 text-blue-300">
          Paste a Discord ID below to verify if it's one of my official accounts. Discord IDs are unique numbers assigned to each user's profile for identification.
        </p>
        <input
            type="text"
            placeholder="Enter Discord ID"
            value={inputId}
            onChange={e => setInputId(e.target.value)}
            className="text-black p-3 rounded-md shadow-lg"
        />
        {inputId && (
            <p className={`text-xl mt-4 ${isIdValid ? 'text-green-500' : 'text-red-500'}`}>
              {isIdValid ? 'This is one of my discord accounts id!' : 'That is NOT my real account, please contact my official account which you can find above.'}
            </p>
        )}
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white p-5 text-center">
        <p>&copy; {new Date().getFullYear()} Lenny. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Home;
